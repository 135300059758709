import styled from "styled-components"

export const ContactUs = styled.div`
  padding: calc(90px + 8vh) 0 8vh 0;
  flex: 1;
  display: flex;
  flex-direction: column;

  & > div {
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`

export const ContactInfo = styled.div`
  flex: 0 1 47%;

  & > h1 {
    font-size: 60px;
    font-weight: 400;
    text-align: left;

    @media (max-width: 768px) {
      font-size: 40px;
      margin-bottom: 25px;
    }

    @media (max-width: 425px) {
      font-size: 30px;
      margin-bottom: 20px;
    }
  }
`

export const ContactInfoLabel = styled.p`
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 22px;
  @media (max-width: 768px) {
    margin-bottom: 12px;
  }
`
