import React from "react"
import { graphql } from "gatsby"

import ContactPage from "../containers/ContactPage"

import { Seo } from "components"

const ContactUsPage = ({ data: { allContentfulPage } }) => {
  const pageData = allContentfulPage.nodes[0]
  return (
    <>
      <ContactPage data={pageData} />
    </>
  )
}

export const Head = ({ location }) => (
  <Seo
    title="Contact us"
    description="Let's work together to build something great"
    ogUrl={location.pathname}
  />
)

export const query = graphql`
  query {
    allContentfulPage(filter: { name: { eq: "Contact Us [page]" } }) {
      nodes {
        id
        name
        sections {
          ... on ContentfulContactUs {
            id
            name
            title {
              raw
            }
            subtitle
          }
        }
      }
    }
  }
`

export default ContactUsPage
