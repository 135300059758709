import React, { useEffect } from "react"

import { CalendyButton } from "./styled"

const Calendy = () => {
  useEffect(() => {
    const head = document.querySelector("head")
    const script = document.createElement("script")
    script.setAttribute(
      "src",
      "https://assets.calendly.com/assets/external/widget.js"
    )
    head.appendChild(script)
  }, [])

  return (
    <div className="App" style={{ overflow: "hidden" }}>
      <CalendyButton
        url="https://calendly.com/nuevacode"
        rootElement={document.getElementById("___gatsby")}
        text="Schedule Meeting"
      />
    </div>
  )
}

export default Calendy
