import React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import { ContactForm, FlexContainer } from "components"
import { Container } from "components/Layout/styled"
import Calendy from "./components/Calendly"
import { ContactInfo, ContactInfoLabel, ContactUs } from "./styled"

const ContactPage = ({ data: { sections } }) => {
  const breakpoints = useBreakpoint()

  const renderSections = section => {
    const { name, id } = section

    switch (name) {
      case "Contact Us [section]":
        return (
          <ContactInfo key={id}>
            {renderRichText(section.title)}
            <ContactInfoLabel>{section.subtitle}</ContactInfoLabel>
            <ContactInfoLabel>Or</ContactInfoLabel>

            <Calendy />
          </ContactInfo>
        )
      default:
        return null
    }
  }

  return (
    <ContactUs>
      <Container>
        <FlexContainer
          gap={breakpoints.md ? "30px" : "10%"}
          alignItems={"flex-start"}
          flexDirection={breakpoints.md ? "column" : "row"}
        >
          {sections.map(section => renderSections(section))}

          <ContactForm width={breakpoints.md ? "100%" : "auto"} />
        </FlexContainer>
      </Container>
    </ContactUs>
  )
}

export default ContactPage
