import { PopupButton } from "react-calendly"
import styled from "styled-components"

// i need extend styles from StyledButton from 'components  for '

export const CalendyButton = styled(PopupButton)`
  align-self: ${props => props.alignSelf || "auto"};
  border: 5em;
  cursor: pointer;
  outline: none;
  font-size: 16px;
  text-transform: uppercase;
  transform: translate(0);
  background-image: linear-gradient(45deg, #4568dc, #b06ab3);
  padding: 15px 22px;
  min-width: 180px;
  border-radius: 21px;
  color: #000;
  transition: box-shadow 0.25s;
  &::after {
    content: "";
    border-radius: 18px;
    position: absolute;
    margin: 2px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: #fff;
  }
  &:hover {
    background-image: linear-gradient(-45deg, #4568dc, #b06ab3);
    box-shadow: 0 12px 24px rgba(128, 128, 128, 0.1);
  }
`
